<div class="page-index">
    <CentralBlock>
        <div class="page-index-content">
            <h3>Dashboard demo</h3>
            <div class="page-index-text">
                <p>
                    This dashboard receives data from the Binance cryptocurrency exchange in real-time using websockets.
                    <br>You can add, remove, move and resize widgets. User data stores in Firebase.
                </p>
                <p>
                    Used technologies:
                </p>
                <ul>
                    <li>TypeScript</li>
                    <li>Nuxt 3</li>
                    <li>Vue 3</li>
                    <li>Pinia</li>
                    <li>Less</li>
                </ul>

                Check out the source code at
                <a target="_blank" href="https://github.com/stervets/binance-ts">
                    https://github.com/stervets/binance-ts
                </a>
                <div class="button-container">
                    <NuxtLink href="/dashboard" class="button-link">
                        Proceed to dashboard
                    </NuxtLink>
                </div>
            </div>
        </div>
    </CentralBlock>
</div>
